<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <base-layout datatableExcludedSize="110px">
            <template slot="header">
                <h1>Affectation des intérêts aux parts sociales</h1>
                <v-row align="center" no-gutters>
                    <v-col>
                        <h2>{{ exercice.libelle }}</h2>
                    </v-col>
                    <v-spacer/>
                    <template v-if="!exercice.cap_exercice.is_affectation_valide">
                        <v-col lg="2" xl="1">
                            <v-import-export-file detail="Importer l'affectation du capital"
                                           @import-file="function(formData){onCapAffectationImport(formData)}"
                                           exportDetail="Télécharger le modèle d'import de l'affectation du capital"
                                           @export-file="exportCapAffectationModele"/>
                        </v-col>
                    </template>
                </v-row>
            </template>
            <template v-slot:main="{ datatableSize }">
                <loading-screen ref="loadingComponent"/>
                <v-container>
                    <v-row justify="center" no-gutters>
                        <v-col md="12" lg="12" xl="12">
                            <v-datatable-affectation-capital
                                :selectedExercice="exercice"
                                :affectations="affectations"
                                :height="datatableSize"
                                :dataChanged="dataChanged"
                                @update-affectations="affectations = $event"
                                @update-data-changed="dataChanged = $event"
                                ref="datatable_affectation_capital"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
                <v-row dense>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn dark color="primary" outlined @click="downloadExcel" block>
                            <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                            Télécharger
                        </v-btn>
                    </v-col>
                    <!-- Petit bouton-->
                    <v-col md="3" lg="3" xl="2" class="ml-2" v-if="exercice.cap_exercice.allow_affectation_ips_montee_capital && exercice.cap_exercice.is_affectation_valide">
                        <v-bulletins-souscription-ips :capExerciceId="exercice.id" :areBulletinsSouscriptionGenerable="are_bulletins_souscription_generable"/>
                    </v-col>
                    <v-spacer/>
                    <template v-if="!exercice.cap_exercice.is_affectation_valide">
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2" class="px-1">
                            <v-btn block color="secondary" @click="saveCapAffectation(validate, errors)">
                                <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                                Enregistrer
                            </v-btn>
                        </v-col>
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2" class="px-1">
                            <v-custom-confirmation-dialog :message="buildConfirmationMessage()"
                                                          openButtonMessage="Valider"
                                                          title="Valider l'affectation des intérêts aux parts sociales"
                                                          :isIcon="false"
                                                          @action-confirmee="validateCapAffectation"
                                                          @open-popup-click="activeValidation=true;"
                                                          @cancel-popup-click="activeValidation=false;"
                                                          :externalValidateForm="validate"
                                                          :externalErrorsList="errors">
                                <template slot="custom-infos">
                                    <div v-if="activeValidation && exercice.cap_exercice.allow_affectation_ips_montee_capital">
                                        <div>
                                            Veuillez sélectionner la catégorie de capital et la date de souscription
                                            qui feront l'objet des soucriptions obligatoires.
                                        </div>
                                        <v-row>
                                            <v-col cols="6">
                                                <ValidationProvider vid="selected_category_id"
                                                                    name="Catégorie de capital"
                                                                    rules="required"
                                                                    v-slot="{ errors }">
                                                    <v-categories-capital-list v-model="selected_category_id"
                                                                               :errors="errors"/>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="6">
                                                <ValidationProvider vid="date_souscription" name="Date de souscription"
                                                                    rules="required"
                                                                    v-slot="{ errors }">
                                                    <DateField v-model="date_souscription"
                                                               ref="date_souscription"
                                                               label="Date de souscription"
                                                               :errors="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </v-custom-confirmation-dialog>
                        </v-col>
                    </template>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" class="px-1">
                        <v-btn block :to="{name: 'cap-exercices'}">Retour</v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import {TOAST} from "@/plugins/toast/definition";
    import XLSX from "xlsx";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import Utils from "@/utils";
    import VCategoriesCapitalList from "@/components/capital/VCategoriesCapitalList";
    import VDatatableAffectationCapital from "@/components/affectationCapital/VDatatableAffectationCapital";
    import VBulletinsSouscriptionIps from "@/components/affectationCapital/VBulletinsSouscriptionIps";
    import DateField from "@/components/DateField";
    import VImportExportFile from "@/components/VImportExportFile";

    /**
     * Vue affichant l'affectation des intérêts aux parts sociales pour les
     * associés concernés
     * @displayName Capital - CapAffectation
     */
    export default {
        components: {
            VCategoriesCapitalList,
            BaseLayout,
            VCustomConfirmationDialog,
            loadingScreen,
            VDatatableAffectationCapital,
            VBulletinsSouscriptionIps,
            DateField,
            VImportExportFile,
        },
        data() {
            return {
                affectations: [],
                noDataTextMessage: "Aucune donnée disponible.",
                confirmationMessage: "Une fois l'affectation des intérêts aux parts sociales validée les montants ne seront plus modifiables." +
                    "<br/>Confirmez-vous cette opération ?",
                debouncedVersementImmediatNet: undefined,
                supportDeversementName: undefined,
                dataChanged: false,
                exercice: {
                    cap_exercice: {
                        is_affectation_valide: false,
                        allow_affectation_ips_montee_capital: false,
                    },
                },
                selected_category_id: undefined,
                date_souscription: undefined,
                activeValidation: false,
                are_bulletins_souscription_generable:false,
            };
        },
        computed: {
        },
        mounted() {
            const id = this.$route.params.exerciceId;
            this.getCapExerciceById(id);
            this.can_generate_bulletins_souscription(id)
        },
        methods: {
            getCapExerciceById(cap_exercice_id) {
                return internalApi.capExercice.getById(cap_exercice_id).then(
                    result => {
                        this.exercice = result;
                    }
                );
            },
            /**
             * Enregistre sans valider l'affectation actuelle
             */
            saveCapAffectation(validate, errors) {
                validate().then(
                    success => {
                        if (success) {
                            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                            internalApi.capExercice.affectation.postSaveCapAffectationByExerciceId(this.exercice.id, this.getObjetForSaveOrValidate())
                                .finally(() => {
                                    this.dataChanged = false;
                                    this.$refs.loadingComponent.resetLoading();
                                });
                        } else {
                            this.showErrorToast(errors);
                        }
                    }
                );
            },
            getObjetForSaveOrValidate() {
                return {"affectations": this.affectations, "date_souscription": this.date_souscription, "category_id": this.selected_category_id}
            },
            /**
             * Valide l'affectation actuelle puis revient à l'écran des exercices
             */
            validateCapAffectation() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.capExercice.affectation.postValidateCapAffectationByExerciceId(this.exercice.id, this.getObjetForSaveOrValidate()).then(res => {
                    this.activeValidation = false;
                    this.dataChanged = false;
                    this.$refs.loadingComponent.resetLoading();
                    this.$router.push({
                        name: "cap-exercices"
                    });
                }).catch((err) => {
                    console.log(err);
                    this.$refs.loadingComponent.resetLoading();
                })
            },
            /**
             * Calcul le montant restant non affecté
             * @param affectation L'affectation actuelle
             * @returns {number}
             */
            getReste(affectation) {
                let reste = affectation.montant_net - affectation.montant_versement_immediat - affectation.montant_montee_capital - affectation.montant_compte_courant;
                return Utils.roundNumber(reste);
            },
            /**
             * Construit le message de confirmation et s'il y a un montant restant non affecté,
             * ajoute un message concernant le support de déversement
             * @returns {string}
             */
            buildConfirmationMessage() {
                let message = "";
                if (this.supportDeversementName) {
                    message += this.buildDeversementMessage();
                }
                message += this.confirmationMessage;
                return message;
            },
            /**
             * Construit un message indiquant que le montant restant partira dans le support
             * de déversement par défaut
             * @returns {string}
             */
            buildDeversementMessage() {
                let flag = false;
                _.forEach(this.affectations, (affectationBenef) => {
                    if (this.getReste(affectationBenef) !== 0) {
                        flag = true;
                    }
                });
                if (flag) {
                    return `<p class="red--text">Attention ! Une partie des intérêts aux parts sociales n’est pas affectée. Par défaut, `
                        + `les montants concernés seront placés sur le support « ${this.supportDeversementName} »</p>`;
                } else {
                    return "";
                }
            },
            /**
             * Display errors from the table
             */
            showErrorToast(raw_errors) {
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            },
            exportCapAffectation() {
                /**
                 * Exporte les données de la datatable sous forme d'un fichier excel
                 */
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.cap_exercice.affectation.exportCapAffectationExcel(this.exercice.id)
                    .then(response => {
                        Utils.downloadFile(response, "affectation_interets_parts_sociales.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            getDataExcel() {
                const headers = this.buildHeadersForExcel();
                const rows = this.buildRowsForExcel();

                return _.concat(headers, rows);
            },
            buildHeadersForExcel() {
                let headers = ["Associé(e)", "Numéro de tiers", "Montant net des IPS", "Montée au capital", "Versement immédiat", "Placement en compte courant d'associé(e)", "Montant restant à affecter"];

                return [headers];
            },
            buildRowsForExcel() {
                let rows = [];
                _.forEach(this.affectations, (affectationAssocie) => {
                    const row = [
                        affectationAssocie.associe_name,
                        affectationAssocie.numero_tiers,
                        affectationAssocie.montant_net,
                        affectationAssocie.montant_montee_capital,
                        affectationAssocie.montant_versement_immediat,
                        affectationAssocie.montant_compte_courant,
                        this.getReste(affectationAssocie),
                    ];

                    rows.push(row);
                });
                return rows;
            },
            downloadExcel: function () {
                const ws = XLSX.utils.aoa_to_sheet(this.getDataExcel());
                const wb = XLSX.utils.book_new();
                wb.props = {
                    Title: "Affectation IPS",
                    Subject: "Affectation des des intérêts aux parts sociales",
                    Author: "EZIScop",
                    CreatedDate: Date.now()
                };
                XLSX.utils.book_append_sheet(wb, ws, "Affectation");
                XLSX.writeFile(wb, "affectation_ips.xlsx");
            },
            onCapAffectationImport(formData) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.import);
                internalApi.capExercice.affectation.importCapAffectationExcel(this.exercice.id, formData)
                    .then(() => {
                        this.$refs.datatable_affectation_capital.getAllAffectationParAssocie();
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            exportCapAffectationModele() {
                /**
                 * Exporte les données de la datatable sous forme d'un fichier excel
                 */
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.capExercice.affectation.exportCapAffectationModeleExcel(this.exercice.id)
                    .then(response => {
                        Utils.downloadFile(response, "modele_affectation_capital.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Vérifier si on peut générer les bulletins IPS
             */
            can_generate_bulletins_souscription(cap_exercice_id) {
                return  internalApi.capExercice.affectation.areBulletinsSouscriptionGenerable(cap_exercice_id).then(
                    result => {
                        this.are_bulletins_souscription_generable = result.are_bulletins_souscription_generable;
                    }
                );
            },
        }
    };
</script>